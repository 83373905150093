/*
* brandcab frontend
* version 1.0
* copyright by Rene Kreupl
*/

// Client Information
// Panzer
// Projectnumber: 00001

// Colors
$color-bg						: #f3f3f3;
$color-wrapper-border			: #f3f3f3;

$color-primary: #029dc2;
$color-text: #000;

// Font
$font-primary: "Raleway";

// Fontsizes
$base-font-size					: 18px;
$base-line-height				: 28px;

// Wrapper Sizes
$wrapper-inner:		1024px;

// Modules
$logo-width-desktop: 220px;

// Emotion
$emotion-overlay-color: #fff;
$emotion-overlay-background: rgba(2,157,194,0.5);
$emotion-overlay-background-mobile: rgba(2,157,194,0.5);

// Button
$button-color-background	: #ff6c45;
$button-color-border		: #ff6c45;

// Footer
$footer-color-base: #004673;
$footer-color-meta: #003055;
$footer-color-font: #fff;

$imagetextcolor-background: rgba(181, 226, 237, 0.29);

/* +++ no changes -> import base +++ */
@import "../main";
@import "../_____fonts/font.Raleway";
