@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local('Raleway Light'), local('Raleway-Light'), url(../Fonts/Raleway-normal-300.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local('Raleway Bold'), local('Raleway-Bold'), url(../Fonts/Raleway-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@if ($font-primary == Raleway) {
	$font-type-primary: sans-serif;
}
@if ($font-secondary == Raleway) {
	$font-type-secondary: sans-serif;
}
